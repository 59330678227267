import React, {useState, useEffect, useContext, useCallback, useMemo} from 'react'
import {I18nContext} from '@/context/i18n/I18nContext'
import RecipientTypeList from './recipient_type_list/RecipientTypeList'
import AddRecipientsContent from './add_recipients_content/AddRecipientsContent'
import WhoToAdd from './who_to_add/WhoToAdd'
import Footer from './footer/Footer'
import LoadMore from './load_more/LoadMore'

import './QuickSelect.scss'
import '@/globals/devour-client'
import {fetchRecipientData} from './fetchRecipientData'
import {buildFooterMessage} from './buildFooterMessage'
import {getSelectedRecipientsUserIdSets} from './getSelectedRecipientsUserIdSets'
import {buildRecipients} from './buildRecipients'

export interface QuickSelectI18nScope {
  quick_select: {
    add_by_quick_select_button: string
    modal_title: string
    recipient_types: {
      people: string
      classes: string
      groups: string
    }
    who_to_add: {
      people_title: string
      classes_title: string
      groups_title: string
      associated_parents: string
      parents: string
      students: string
      staff: string
      guests: string
      everyone: string
      no_student_square_message: string
    }
    recipient_list: {
      select_people: string
      select_classes: string
      select_groups: string
      search: string
      group: {
        title: string
      }
      tooltip: {
        teacher: string
        principal: string
        staff: string
        parent: string
        generic: string
      }
      empty: {
        people: string
        classes: string
        groups: string
        suggestion: string
      }
      error: {
        header: string
        subheader: string
      }
    }
    footer: {
      make_selections_up_to_recipients: string
      is_not_contactable: {
        one: string
        other: string
      }
      and: string
      and_n_more: string
      will_be_added: {
        one: string
        other: string
      }
      reduce_to_fewer_than: string
      parent: string
      parents: string
      student: string
      students: string
      staff_member: string
      staff_members: string
      guest: string
      guests: string
      add_recipients: string
    }
  }
}

export interface Recipient {
  id: string
  full_name: string
  first_name?: string
  last_name?: string
  type: string
  associated_user_id?: string
  title?: string
  searchable_class_list?: string
  login_allowed?: boolean
  parents?: Recipient[]
  staff_recipient_ids?: number[]
  parent_recipient_ids?: number[]
  student_recipient_ids?: number[]
  guest_recipient_ids?: number[]
}

export interface WhoToAdd {
  user: string[]
  section: string[]
  group: string[]
}

export interface WhoToAddOption {
  key: string
  description: string
}

export type WhoToAddKey = keyof WhoToAdd

export interface RecipientType {
  key: WhoToAddKey
  description: string
  iconName: string
}

export interface RecipientToAdd {
  recipient_type: string
  recipient_id: string
  descriptive_name: string
  roles?: string[]
  user_ids: number[]
}

export type UserRole = 'parent' | 'student' | 'staff' | 'guest'

interface QuickSelectType {
  closeModal: () => void
  currentUser: {id: number; role: string}
  currentInstituteId: number
  currentInstituteType: string
  currentInstituteHasStudentSquare: boolean
  onAddRecipientsClick: (recipients: RecipientToAdd[]) => void
  maximumRecipientsToAdd: () => number
  initialRecipients: () => Set<number>
  // this prop should be used in tests only
  // we tried other non intrusive approaches mocking the IntersectionObserver but they generated flaky test
  loadMoreMocker?: {loadMore: null | (() => void)}
}

interface UserResponseRecipient {
  id: string
  type: 'student_recipient' | 'recipient'
  full_name: string
  first_name: string
  last_name: string
  title: string
  parents: Recipient[]
}

export interface Meta {
  start_from: string
  page_size: number
}

export interface UserResponseType {
  data: {
    id: string
    staff_recipients: UserResponseRecipient[]
    staff_recipients_count: number
    student_recipients: UserResponseRecipient[]
    student_recipients_count: number
    type: 'user_recipients'
  }
  meta: Meta
}

interface SectionResponseRecipient {
  section_id: string
  type: 'section_recipient'
  section_name: string
  staff_recipient_ids: number[]
  parent_recipient_ids: number[]
  student_recipient_ids: number[]
}

export interface SectionResponseType {
  meta: Meta
  data: SectionResponseRecipient[]
}

interface GroupResponseRecipient {
  group_id: string
  type: 'group_recipient'
  group_name: string
  staff_recipient_ids: number[]
  parent_recipient_ids: number[]
  student_recipient_ids: number[]
  guest_recipient_ids: number[]
}

export interface GroupResponseType {
  meta: Meta
  data: GroupResponseRecipient[]
}

export type ResponseType = UserResponseType | SectionResponseType | GroupResponseType

export const countGroupUsers = (groupRecipient: GroupResponseRecipient): number => {
  const allRecipients = new Set()
  groupRecipient.guest_recipient_ids.forEach(allRecipients.add, allRecipients)
  groupRecipient.parent_recipient_ids.forEach(allRecipients.add, allRecipients)
  groupRecipient.staff_recipient_ids.forEach(allRecipients.add, allRecipients)
  groupRecipient.student_recipient_ids.forEach(allRecipients.add, allRecipients)
  return allRecipients.size
}

const addOrRemoveRecipient = (recipient: Recipient, selectedRecipients: Recipient[]) => {
  const index = selectedRecipients.findIndex(
    (selectedRecipient) =>
      recipient.type === selectedRecipient.type && recipient.id === selectedRecipient.id
  )
  const recipientIsNotSelected = index === -1
  if (recipientIsNotSelected) {
    return [...selectedRecipients, recipient]
  } else {
    const selectedRecipientsWithoutItem = [...selectedRecipients]
    selectedRecipientsWithoutItem.splice(index, 1)
    return selectedRecipientsWithoutItem
  }
}

const getNonContactableRecipients = (recipient: Recipient, whoToAdd: WhoToAdd) => {
  const nonContactableUsers = []
  if (recipient.type === 'Student') {
    if (whoToAdd.user.includes('students') && !recipient.login_allowed) {
      nonContactableUsers.push(recipient.full_name)
    }
    recipient.parents?.forEach((parent) => {
      if (whoToAdd.user.includes('parents') && !parent.login_allowed) {
        nonContactableUsers.push(parent.full_name)
      }
    })
  }

  return nonContactableUsers
}

const addOrRemoveWhoToAdd = (option: string, recipientType: WhoToAddKey, whoToAdd: WhoToAdd) => {
  const index = whoToAdd[recipientType].indexOf(option)
  const whoToAddIsNotSelected = index === -1
  if (whoToAddIsNotSelected) {
    const whoToAddWithNewOption = {...whoToAdd}
    if (recipientType === 'group') {
      if (whoToAddWithNewOption[recipientType].includes('everyone') || option === 'everyone') {
        whoToAddWithNewOption[recipientType] = []
      }
    }
    whoToAddWithNewOption[recipientType].push(option)
    return whoToAddWithNewOption
  } else {
    const whoToAddWithoutOption = {...whoToAdd}
    whoToAddWithoutOption[recipientType].splice(index, 1)
    return whoToAddWithoutOption
  }
}

const QuickSelect = ({
  closeModal,
  currentUser,
  currentInstituteId,
  currentInstituteType,
  currentInstituteHasStudentSquare,
  onAddRecipientsClick,
  maximumRecipientsToAdd,
  initialRecipients,
  loadMoreMocker,
}: QuickSelectType) => {
  const i18n = useContext(I18nContext) as QuickSelectI18nScope
  const i18nQS = i18n.quick_select

  const modalTitle: {[key in WhoToAddKey]: string} = {
    user: i18nQS.who_to_add.people_title,
    section: i18nQS.who_to_add.classes_title,
    group: i18nQS.who_to_add.groups_title,
  }
  const recipientTypes: RecipientType[] = [
    {key: 'user', description: i18nQS.recipient_types.people, iconName: 'fa-user'},
    {key: 'section', description: i18nQS.recipient_types.classes, iconName: 'fa-screen-users'},
    {key: 'group', description: i18nQS.recipient_types.groups, iconName: 'fa-users'},
  ]
  const whoToAddOptions = (type: WhoToAddKey, recipients: Recipient[]): WhoToAddOption[] => {
    let options: WhoToAddOption[] = []
    if (type === 'user') {
      options = [
        {key: 'parents', description: i18nQS.who_to_add.associated_parents},
        {key: 'students', description: i18nQS.who_to_add.students},
      ]
    }
    if (type === 'section') {
      options = [
        {key: 'parents', description: i18nQS.who_to_add.associated_parents},
        {key: 'students', description: i18nQS.who_to_add.students},
        {key: 'staff', description: i18nQS.who_to_add.staff},
      ]
    }
    if (type === 'group') {
      options = [
        {key: 'everyone', description: i18nQS.who_to_add.everyone},
        {key: 'parents', description: i18nQS.who_to_add.parents},
        {key: 'students', description: i18nQS.who_to_add.students},
        {key: 'staff', description: i18nQS.who_to_add.staff},
      ]
      const thereIsAtLeastOneGuestInRecipients = recipients.some(
        (r) => (r?.guest_recipient_ids?.length || 0) > 0
      )
      if (thereIsAtLeastOneGuestInRecipients) {
        options.push({key: 'guests', description: i18nQS.who_to_add.guests})
      }
    }

    return options
  }

  const [data, setData] = useState<{recipients: Recipient[]}>({recipients: []})
  const [searchQuery, setSearchQuery] = useState('')
  const [cursorToLoadMorePages, setCursorToLoadMorePages] = useState<null | string>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [selectedRecipients, setSelectedRecipients] = useState<Recipient[]>([])
  const [lastUncontactableUsers, setLastUncontactableUsers] = useState<string[]>([])
  const [recipientType, setRecipientType] = useState<RecipientType>(
    recipientTypes[0] as RecipientType
  )

  const thereAreContactableStudents = useMemo(
    () =>
      currentInstituteHasStudentSquare &&
      data.recipients.some((recipient) => recipient.type === 'Student' && recipient.login_allowed),
    [data.recipients]
  )

  const thereAreStudents = useMemo(
    () => data.recipients.some((recipient) => recipient.type === 'Student'),
    [data.recipients]
  )

  const currentUserIsAParent = currentUser.role === 'parent'

  const [whoToAdd, setWhoToAdd] = useState<WhoToAdd>({
    user: ['parents'],
    section: ['parents'],
    group: ['staff', 'parents'],
  })

  useEffect(() => {
    setData({recipients: []})
    setLoading(true)
    fetchRecipientData(
      currentInstituteType,
      currentInstituteId,
      recipientType.key,
      i18nQS,
      searchQuery,
      null
    )
      .then((response: {recipients: Recipient[]; cursor: string | null}) => {
        if (recipientType.key !== 'user' && response.recipients.length > 0) {
          setCursorToLoadMorePages(response.cursor)
        }
        setData({recipients: response.recipients})
      })
      .finally(() => {
        setLoading(false)
      })
      .catch(() => {
        setError(true)
      })
  }, [recipientType, searchQuery])

  const loadMore = useCallback(() => {
    if (!cursorToLoadMorePages) return
    fetchRecipientData(
      currentInstituteType,
      currentInstituteId,
      recipientType.key,
      i18nQS,
      searchQuery,
      cursorToLoadMorePages
    )
      .then((response: {recipients: Recipient[]; cursor: string | null}) => {
        setData({recipients: data.recipients.concat(response.recipients)})
        if (recipientType.key !== 'user' && data.recipients.length > 0) {
          setCursorToLoadMorePages(response.cursor)
        }
      })
      .catch(() => {
        setError(true)
      })
  }, [
    cursorToLoadMorePages,
    currentInstituteType,
    currentInstituteId,
    recipientType,
    searchQuery,
    i18nQS,
    data,
  ])

  if (loadMoreMocker) {
    loadMoreMocker.loadMore = loadMore
  }

  const recipientUserIdSets = getSelectedRecipientsUserIdSets(
    selectedRecipients,
    whoToAdd,
    currentInstituteHasStudentSquare,
    initialRecipients()
  )

  const footerMessage = buildFooterMessage(recipientUserIdSets, maximumRecipientsToAdd(), i18nQS)

  const noRecipientIsSelected = recipientUserIdSets.all.size === 0
  const tooManyRecipientsAreSelected = recipientUserIdSets.all.size > maximumRecipientsToAdd()
  const disableAddRecipientsButton = noRecipientIsSelected || tooManyRecipientsAreSelected

  const onRecipientClick = (recipient: Recipient) => {
    const newRecipients = addOrRemoveRecipient(recipient, selectedRecipients)
    const adding = newRecipients.length > selectedRecipients.length
    setSelectedRecipients(newRecipients)

    if (adding) {
      setLastUncontactableUsers(getNonContactableRecipients(recipient, whoToAdd))
    } else {
      setLastUncontactableUsers([])
    }
  }

  const addRecipients = () => {
    const recipientsToAdd: RecipientToAdd[] = buildRecipients(
      selectedRecipients,
      currentInstituteHasStudentSquare,
      whoToAdd
    )
    onAddRecipientsClick(recipientsToAdd)
    closeModal()
  }

  const recipients = data && data.recipients ? data.recipients : []

  let loadMoreElement: React.ReactElement | null = null
  if (cursorToLoadMorePages) {
    loadMoreElement = <LoadMore key={cursorToLoadMorePages} callWhenVisible={loadMore} />
  }

  const isPeopleTab = recipientType.key === 'user'
  const showAssociatedParentsMessage =
    isPeopleTab && !thereAreContactableStudents && thereAreStudents

  const showWhoToAdd =
    (isPeopleTab && thereAreContactableStudents && !currentUserIsAParent) || !isPeopleTab
  return (
    <>
      <div className="fr-overlay" />
      <div className="modal react-modal fade in big-container">
        <div className="title">
          {i18nQS.modal_title}
          <button type="button" className="close" onClick={closeModal} aria-label="Close">
            <i className="fa-solid fa-xmark" />
          </button>
        </div>
        <div className="main-column">
          <div className="left-menu">
            <RecipientTypeList
              onSelect={(recipientType: RecipientType) => setRecipientType(recipientType)}
              types={recipientTypes}
              selected={recipientType.key}
            />
          </div>
          <div className="right-content">
            {showWhoToAdd && (
              <WhoToAdd
                title={modalTitle[recipientType.key]}
                whoToAddOptions={whoToAddOptions(recipientType.key, recipients)}
                whoToAdd={whoToAdd[recipientType.key]}
                onWhoToAddChange={(option: string) => {
                  setWhoToAdd(addOrRemoveWhoToAdd(option, recipientType.key, whoToAdd))
                }}
              />
            )}
            {showAssociatedParentsMessage && (
              <div className="no-student-square">
                <i className="fa-solid fa-circle-info" />
                <div className="no-student-square-message">
                  {i18nQS.who_to_add.no_student_square_message}
                </div>
              </div>
            )}
            <AddRecipientsContent
              loading={loading}
              error={error}
              currentUser={currentUser}
              recipientType={recipientType.key}
              recipients={recipients}
              onRecipientClick={onRecipientClick}
              selectedRecipients={selectedRecipients}
              finalElement={loadMoreElement}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
        <div className="footer">
          <Footer
            footerMessage={footerMessage}
            lastUncontactableUsers={lastUncontactableUsers}
            onAddRecipientsClick={addRecipients}
            disabled={disableAddRecipientsButton}
          />
        </div>
      </div>
    </>
  )
}

export default QuickSelect
