import React from 'react'

interface Props {
  title: string
  selected: boolean
  onClick: () => void
  iconName: string
}
const Item = ({title, selected, onClick, iconName}: Props) => {
  return (
    <div onClick={onClick} className={`list-item ${selected ? 'selected' : ''}`}>
      <i
        className={`fa-regular icon ${iconName}`}
        style={{fontSize: iconName === 'fa-user' ? '18px' : '17px'}}
      />
      <div className="list-title">{title}</div>
    </div>
  )
}

export default Item
